import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Support from './Pages/support'
import ARLPrivacy from './Pages/privacy'
import ARLAccountDelete from './Pages/deleteAcc'
import Home from './Pages/home'




const App = () => {
return(
<BrowserRouter>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/accountdelete" element={<ARLAccountDelete />} />
      <Route path="/support" element={<Support />} />
      <Route path="/privacy" element={<ARLPrivacy/>} />
    </Routes>
</BrowserRouter>

)
}

export default App