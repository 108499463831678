import arlIcon from '../assets/art/arlicon.png'
import './SplashStyles.css'

const ARLPrivacy = () => {

  return (
    <div className="App">
      <div className="App-header">
      <div className='NavBar' style={{justifyContent:"flex-start", backgroundColor:"#1c1c1c",}}>
            <img className='NavButtonLogo' src={arlIcon} alt="Logo" />
          <div style={{width:"5em"}} />
          <div style={{fontSize:"1.6em"}}>ARL Privacy Policy</div>
        </div>
      
        <div className='hero-wrapper' style={{height:"200px", backgroundColor:"#1c1c1c", width:"100%"}}>
          {/* <img src={nameLogo} style={{height:"26em"}} alt="ARL logo"/> */}
          <div className='hero-text-box'>
            <h1 style={{fontSize:"2.5em", color:"#00aeef"}}>ARL Privacy Policy</h1>
            <p style={{fontSize:"1.2em", color:"#fff"}}>Your privacy is important to us. Learn how we handle your data in ARL.</p>
          </div>
        </div>

        {/* Privacy Policy Details */}
        <div className='content-wrapper' style={{backgroundColor:"#f7f7f7", display:"flex",flexDirection:"column",textAlign:"left"}}>
          <h2>• What Information We Collect</h2>
          <p>At ARL, we believe in transparency and minimal data collection. We only collect essential information such as your name and email to create your account, track your progress, and provide a personalized experience.</p>
          
          <h2>• How We Use Your Information</h2>
          <p>Your data serves one purpose: to enhance your ARL experience. We use your information to manage your account, support your journey in trait leveling, and keep you informed about updates and community activity.</p>
          
          <h2>• Data Retention</h2>
          <p>We retain your personal information for as long as your account exists. Post data is deleted entirely upon trashing the post or when it is removed from the feed. *Successful Trophy posts are the only post that is stored forever.</p>

          <h2>• User Rights</h2>
          <p>You have the right to access, update, or delete your information at any time through the app's settings page. You may also set your account to Private, which disables it from the search bar. This setting is recommended for anyone under 18.</p>

          <h2>• Childrens Privacy</h2>
          <p>Our app is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13.</p>

          <h2>• Cookies and Tracking</h2>
          <p>We do not use cookies or any tracking technologies to monitor your behavior on our app.</p>

          <h2>• Data Sharing, Third Parties, and Disclosure</h2>
          <p>We're not in the business of selling data. In fact, we don't share your personal information with third parties for marketing or advertising. Your trust is our top priority, and we respect your privacy by not monetizing your data.</p>
          
          <h2>• International Data Transfers</h2>
          <p>Your information may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may differ from those of your jurisdiction.</p>

          <h2>• Your Privacy Choices</h2>
          <p>You're in control. With ARL, you can update, manage, or delete your account information at any time. We empower you to make choices about your data and privacy settings directly within the app.</p>
          
          <h2>• Security Measures</h2>
          <p>Your data's security is paramount. We use industry-standard measures to protect your information and maintain the integrity of our platform. Rest assured, your data is guarded as if it were our own.</p>

          <h2>• Technology Stack & Open Source</h2>
          <p>ARL is open source @ Github.com/SebTheRed/ARL for anyone to contribute to. Your account data is not shared with the developers on Github. ARL is built with React-Native and uses Firebase as its BaaS, that's our entire tech stack.</p>
          
          <h2>• Changes to the Privacy Policy</h2>
          <p>Any changes we make to our privacy policy will be posted on this page, and, where appropriate, notified to you by email or when you start the app.</p>

          <h2>• Contact Us</h2>
          <p>Have privacy questions? Our door is always open. Reach out to us at <a href="mailto:app4reallife@gmail.com">app4reallife@gmail.com</a> for any privacy inquiries or concerns. We're here to help!</p>
        </div>

        <div className='pane-wrapper' style={{height:"200px", backgroundColor:"#1c1c1c"}}>
          <div className='Footer-section'>
            <p style={{color:"#fff"}}>© 2023 Volt Applications - All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ARLPrivacy