import './SplashStyles.css'
import arlIcon from '../assets/art/arlicon.png'

const ARLPrivacy = () => {

  return (
    <div className="App">
      <div className="App-header">
      <div className='NavBar' style={{justifyContent:"flex-start", backgroundColor:"#1c1c1c",}}>
            <img className='NavButtonLogo' src={arlIcon} alt="Logo" />
          <div style={{width:"5em"}} />
          <div style={{fontSize:"1.6em"}}>Welcome to ARL</div>
        </div>
      
        <div className='hero-wrapper' style={{height:"200px", backgroundColor:"#1c1c1c", width:"100%"}}>
          {/* <img src={nameLogo} style={{height:"26em"}} alt="ARL logo"/> */}
          <div className='hero-text-box'>
            <h1 style={{fontSize:"2.5em", color:"#00aeef"}}>Its fun</h1>
            <p style={{fontSize:"1.2em", color:"#fff"}}>.</p>
          </div>
        </div>

        {/* Privacy Policy Details */}


        <div className='pane-wrapper' style={{height:"200px", backgroundColor:"#1c1c1c"}}>
          <div className='Footer-section'>
            <p style={{color:"#fff"}}>© 2023 Volt Applications - All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ARLPrivacy