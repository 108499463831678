import arlIcon from '../assets/art/arlicon.png'
import { useState } from 'react';
import { deleteUser, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {auth} from '../Firebase/firebase'

import './SplashStyles.css'


const ARLAccountDelete =() => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [loadingBool,setLoadingBool] = useState(false)
  const [message,setMessage] = useState("")
  const [userData, setUserData] = useState()

  const navigate = useNavigate();


  const handleButtonPress = async () => {

    try {
      setLoadingBool(true);

      // Sign in the user
      await signInWithEmailAndPassword(auth, username, password);
      const user = auth.currentUser;
      console.log("user: ",user)
      setUserData(user)

    } catch (err) {
      console.error(err);
      setLoadingBool(false);
      alert('Error loading account');
    }
  };

  const handleDelete = async () => {
    if (!isConfirmed) {
      alert('Please confirm the action.');
      return;
    }
    try {
      await deleteUser(userData)
      // Sign out the user
      await signOut(auth);
      navigate('/')

      // Set loading state to false
      setLoadingBool(false);

      alert('Account deleted successfully');
    } catch(err) {
      alert("Account failed to delete! Try again...")
    }
  }

  return (
    <div className="App">
      <div className="App-header">
        <div className='NavBar' style={{ justifyContent: "flex-start", backgroundColor: "#1c1c1c" }}>
          <img className='NavButtonLogo' src={arlIcon} alt="Logo" />
          <div style={{ width: "5em" }} />
          <div style={{ fontSize: "1.6em" }}>ARL Account Deletion</div>
        </div>
      
        <div className='hero-wrapper' style={{ height: "200px", backgroundColor: "#1c1c1c", width: "100%" }}>
          <div className='hero-text-box'>
            <h1 style={{ fontSize: "2.5em", color: "#00aeef" }}>Delete Your Account</h1>
            <p style={{ fontSize: "1.2em", color: "#fff" }}>Once you do this, it will be gone forever.</p>
          </div>
        </div>
        <div className='content-wrapper' style={{ backgroundColor: "#1c1c1c", display: "flex", flexDirection: "column", textAlign: "left", alignItems:"center", padding: "1em", width: "100%" }}>
          <form>
            <label style={{color:"#fff", fontSize:"1.5em"}}>
              Username:
              <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            </label>
            <br />
            <label style={{color:"#fff", fontSize:"1.5em"}}>
              Password:
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
            <br />
            {!userData && <button type="button" onClick={handleButtonPress}>First Log In..</button>}
            {userData && 
            <>
              <label style={{ color: "#fff", fontSize: "1.5em" }}>
                <input type="checkbox" checked={isConfirmed} onChange={(e) => setIsConfirmed(e.target.checked)} />
                Confirm account deletion
              </label>
              <br />
              <button type="button" onClick={handleDelete}>Delete Account</button>
            </>
            }
            </form>
        </div>
        <div className='pane-wrapper' style={{ height: "200px", backgroundColor: "#1c1c1c" }}>
          <div className='Footer-section'>
            <p style={{ color: "#fff" }}>© 2023 Volt Applications - All rights reserved</p>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default ARLAccountDelete